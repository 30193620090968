const fun = {   
    /**
     * 格式化日期时间
     * @param {number|string} datetime - 要格式化的日期时间（时间戳或日期字符串）
     * @param {string} fmt - 格式化模板，默认为 'yyyy-MM-dd hh:mm:ss'
     * @returns {string} 格式化后的日期时间字符串
     */
    formatDate(datetime,fmt='yyyy-MM-dd hh:mm:ss') {
        if (parseInt(datetime)==datetime) {
          if (datetime.length==10) {
            datetime=parseInt(datetime)*1000;
          } else if(datetime.length==13) {
            datetime=parseInt(datetime);
          }
        }
        datetime=new Date(datetime);
        var o = {
        "M+" : datetime.getMonth()+1,                 //月份
        "d+" : datetime.getDate(),                    //日
        "h+" : datetime.getHours(),                   //小时
        "m+" : datetime.getMinutes(),                 //分
        "s+" : datetime.getSeconds(),                 //秒
        "q+" : Math.floor((datetime.getMonth()+3)/3), //季度
        "S"  : datetime.getMilliseconds()             //毫秒
        };
        if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (datetime.getFullYear()+"").substr(4 - RegExp.$1.length));
        for(var k in o)
        if(new RegExp("("+ k +")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        return fmt;
    },
    /**
     * 获取指定秒数之前的日期时间
     * @param {number} seconds - 指定的秒数
     * @returns {Date} 指定秒数之前的日期时间
     */
    getDateTimeBeforeSeconds(seconds) {
      const now = new Date();
      return new Date(now.getTime() - seconds * 1000);
    },
    /**
     * 左侧补零
     * @param {string} str - 需要补零的字符串
     * @returns {string} 补零后的字符串
     */
    padLeftZero(str) {
        return ('00' + str).substr(str.length);
    },

    /**
     * 获取当前日期的格式化字符串
     * @returns {string} 格式为 'YYYY-MM-DD' 的当前日期字符串
     */
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },

    /**
     * 根据时间属性对对象数组进行排序
     * @param {Array} objects - 要排序的对象数组
     * @param {string} timeProperty - 用于排序的时间属性名
     * @param {boolean} ascending - 是否升序排列，默认为 true
     * @returns {Array} 排序后的对象数组
     */
    sortObjectsByDateTime(objects, timeProperty, ascending=true) {
      objects.sort(function(a, b) {
        var timeA = new Date('1970-01-01T' + a[timeProperty] + 'Z');
        var timeB = new Date('1970-01-01T' + b[timeProperty] + 'Z');
        if (ascending) {
          return timeA - timeB;
        } else {
          return timeB - timeA;
        }
      });
      return objects;
    },
    //身份证号 计算年龄
    getAge(identityCard){
        var len = (identityCard + "").length;
        if (len == 0) {
            return 0;
        } else {
            if ((len != 15) && (len != 18))//身份证号码只能为15位或18位其它不合法
            {
                return 0;
            }
        }
        var strBirthday = "";
        if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
        {
            strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
        }
        if (len == 15) {
            strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
        }
        //时间字符串里，必须是“/”
        var birthDate = new Date(strBirthday);
        var nowDateTime = new Date();
        var age = nowDateTime.getFullYear() - birthDate.getFullYear();
        //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
        if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    },
    //数组排序
    sortArr(arr, attr ='id',flag=true){
      for (var i = 0; i < arr.length - 1; i++) {//比较arr.length-1轮
        for (var j = i + 1; j < arr.length; j++) {
            if (arr[i][attr] > arr[j][attr] && flag == true) {//交换
                var temp = arr[i][attr];//临时变量
                arr[i][attr] = arr[j][attr];
                arr[j][attr] = temp;

            }else if(arr[i][attr] < arr[j][attr] && flag == false){
                var temp = arr[i][attr];//临时变量
                arr[i][attr] = arr[j][attr];
                arr[j][attr] = temp;
            }
        }
    }
    return arr;
    },
    mymethod(birthday){//根据日期算年龄
      if(birthday){
        birthday=birthday.split('-');
        // 新建日期对象
        let date = new Date();
        // 今天日期，数组，同 birthday
        let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
        // 分别计算年月日差值
        let age = today.map((val, index) => {
            return val - birthday[index]
        })
        // 当天数为负数时，月减 1，天数加上月总天数
        if (age[2] < 0) {
            // 简单获取上个月总天数的方法，不会错
            let lastMonth = new Date(today[0], today[1], 0)
            age[1]--
            age[2] += lastMonth.getDate()
        }
        // 当月数为负数时，年减 1，月数加上 12
        if (age[1] < 0) {
            age[0]--
            age[1] += 12
        }
        // console.log(age[0]+'岁'+age[1]+'月'+age[2]+'天');
        return age[0]
      }
    },
    // 计算两个时间之间的差值，并判断是否超过指定的分钟数
    getFutureDate(time1, time2, Minu) {
      const parsedTime1 = new Date(time1); // 将第一个时间字符串解析为日期对象
      const parsedTime2 = new Date(time2); // 将第二个时间字符串解析为日期对象
      if (!time1 || !time2 || !Minu) { // 检查输入的时间和分钟数是否有效
        return false; // 如果无效，返回false
      }
      // 计算时间差（毫秒）
      const timeDifference = Math.abs(parsedTime1 - parsedTime2); // 计算两个时间的绝对差值

      // 转换为分钟
      const minutesDifference = timeDifference / (1000 * 60); // 将毫秒转换为分钟
      return minutesDifference > Minu; // 返回时间差是否大于指定的分钟数
    },
    duplicateRemoval(array,key){
      return array.filter((item, index, self) =>index === self.findIndex((t) => t[key] === item[key]));
    },
}


export default fun;